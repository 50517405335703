.MainContainer {
  display: flex;
  background-color: black;
}

.image-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.image-container-img {
  width: 50px;
  border-radius: 99;
}

.text-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
}

.snipe {
  font-size: 12px;
  color: #a1a1a1;
}

.snipe-img {
  filter: invert(26%) sepia(82%) saturate(5715%) hue-rotate(353deg)
    brightness(91%) contrast(126%);
  width: 20px;
  margin-right: 20px;
}
