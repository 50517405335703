.App {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  background: url("./assets/img/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  background-color: rgb(32, 32, 32);
}
.header-logo {
  height: 18px;
  padding-left: 25px;
}
.header-logOut {
  padding-right: 25px;
}
.header-logOut-btn {
  background-image: url("./assets/img/Ellipse\ 11.png");
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-position: center;
  cursor: pointer;
}
.containerSignIn {
  height: 513px;
  width: 367px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  background-color: rgb(32, 32, 32);
  border-radius: 4%;
}

.hero {
  margin-bottom: 10px;
}

.logIn-btn {
  height: 50px;
  width: 267px;
  border-radius: 99px;
  cursor: pointer;
  background-color: white;
  color: black;
}
.item {
  padding: 10px;
}

.hero-p {
  color: white;
}

.marketplaces {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.loggedInView {
  margin: auto;
  color: white;
}

.listBox {
  width: 450px;
  height: 170px;

  text-align: center;
  margin-right: 20px;
  padding-left: -70px;
  margin-top: 10px;
  padding-top: 10px;
}
ul {
  position: relative;
  z-index: 100;
}
ul::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.listbox-button {
  width: 450px;
}
.listbox-text {
  background-color: #19191d;
  border: 0.5px solid #6b6b6b;
  padding: 15px 140px;
  border-radius: 12px;
}
.listBox-option {
  color: red;
}
.getMore-btn {
  background-color: #19191d;
  height: 30px;
}

.main-container {
  width: 1000px;
  height: 600px;

  display: flex;
}
.main-container-left {
  flex: 1;
  background-color: black;
  border-radius: 12px 0 0 12px;
}
.main-container-right {
  flex: 1;
  background-color: rgb(35, 35, 36);
  border-radius: 0 12px 12px 0;
}

.main-container-left-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 120px;
}
.main-container-left-top > div > p {
  font-size: 12px;
  color: #a1a1a1;
}

.collection-img {
  width: 100px;
  border-radius: 12px;
}
.info-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 60px;
  margin-top: 30px;
}

.box {
  background-color: #19191d;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.main-container-mid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mid-box {
  display: flex;
  margin: 20px;
  margin-top: 30px;
}
.mid-box-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.mid-box-text > p {
  font-size: 12px;
  color: #a1a1a1;
}
.mid-box-text > h2 {
  font-size: 22px;
}

.main-container-low {
  color: white;
  display: flex;
  border: 1px solid #ad00ff;
  margin: 0px 30px 30px 30px;
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-weight: bold;
}

.main-container-low-left {
  width: 100px;
}
.CircularProgressbar-path {
  stroke: #00ff94 !important;
}
.CircularProgressbar-trail {
  stroke: #2a154f !important;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}
.main-container-low-right {
  flex: 1;

  display: flex;

  justify-content: center;
  align-items: center;
  gap: 50px;
}
.main-container-low-right > div > p {
  font-size: 12px;
  color: #a1a1a1;
}

.main-container-right-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  margin-left: 30px;
}
.listBox-container {
  margin: auto;
}
.dropdown {
  width: 450px;
  height: 440px;
  position: absolute;

  overflow: scroll;
}
ul {
  position: relative;
  z-index: 100;
}
ul::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-container-right-up > p {
  font-size: 12px;
  color: #6b6b6b;
}
.main-container-right-mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -80px;
}
.main-container-right-mid > p {
  font-size: 12px;
  color: #6b6b6b;
  align-self: flex-start;
  margin-left: 30px;
}
.mid-right-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 450px;
  height: 45px;
  background-color: #19191d;
  border-radius: 12px;
  margin: 10px;
  margin-top: 20px;
  gap: 30px;
  cursor: pointer;
}
.mid-right-box > img {
  margin-left: 20px;
}
.main-container-right-low {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-container-right-low > p {
  font-size: 12px;
  color: #6b6b6b;
  align-self: flex-start;
  margin-left: 30px;
  margin-top: 20px;
}

.date-pick-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #19191d;
  gap: 30px;
  border: 0.7px solid white;
  margin-top: 10px;
  width: 450px;
  border-radius: 12px;
}

.date-box {
  padding: 10px 20px;

  margin: 5px;
  cursor: pointer;
  border-radius: 12px;
}
.selected {
  background-color: #ad00ff;
}
.selectedBox {
  border: 1px solid white;
}

#divider1 {
  width: 450px;
  margin-bottom: 10px;
}

#divider2 {
  width: 450px;
  margin-top: 10px;
}
.main-container-right-footer {
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -10px;
}
.main-container-right-footer-left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.right-footer-img {
  width: 30px;
  color: #ad00ff;
}

.print-btn {
  padding: 20px 30px;
  background-color: white;
  color: black;
  margin: 10px;
  width: 450px;
  border-radius: 12px;
  margin-top: 30px;
}

@media screen and (min-height: 1400px) {
  .App {
    height: 100vh;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 100px;
    background-color: rgb(32, 32, 32);
  }
  .header-logo {
    height: 28px;
    padding-left: 25px;
  }
  .header-logOut {
    padding-right: 25px;
  }
  .header-logOut-btn {
    background-image: url("./assets/img/Ellipse\ 11.png");
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    cursor: pointer;
  }
  .containerSignIn {
    height: 713px;
    width: 567px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    background-color: rgb(32, 32, 32);
    border-radius: 4%;
  }

  .hero {
    margin-bottom: 10px;
  }
  .hero-h1 {
    width: 367px;
    height: 25px;
  }
  .hero-img {
    width: 220px;
  }

  .logIn-btn {
    height: 100px;
    width: 367px;
    border-radius: 99px;
    cursor: pointer;
    background-color: white;
    color: black;
    font-size: 24px;
  }
  .item {
    padding: 10px;
  }

  .hero-p {
    color: white;
    font-size: 24px;
  }

  .marketplaces {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }
  .marketplaces > a > img {
    width: 70px;
  }

  .loggedInView {
    margin: auto;
    color: white;
  }

  .listBox {
    border-radius: 12px;
    height: 200px;
    margin-right: 20px;
    margin-top: 10px;
    overflow: auto;
    padding-left: -70px;
    text-align: center;
    width: 600px;
    font-size: 24px;
    margin-left: 20px;
    padding-top: 20px;
  }
  ul {
    position: relative;
    z-index: 100;
  }
  ul::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .listbox-button {
    width: 550px;
    border-radius: 12px;
    height: 80px;
  }
  .listbox-text {
    background-color: #19191d;
    padding: 15px 140px;
    font-size: 36px;
  }
  .text-purple-400 {
    font-size: 24px;
  }
  .image-container-img {
    width: 80px;
  }
  .listBox-option {
    color: red;
  }

  .main-container {
    width: 1400px;
    height: 900px;

    display: flex;
  }
  .main-container-left {
    flex: 1;
    background-color: black;
    border-radius: 12px 0 0 12px;
  }
  .main-container-right {
    flex: 1;
    background-color: rgb(35, 35, 36);
    border-radius: 0 12px 12px 0;
  }

  .main-container-left-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 220px;
  }
  .main-container-left-top > div > h1 {
    font-size: 36px;
  }
  .main-container-left-top > div > p {
    font-size: 24px;
    color: #a1a1a1;
  }

  .collection-img {
    width: 200px;
    border-radius: 12px;
  }
  .info-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 60px;
    margin-top: 30px;
  }

  .box {
    background-color: #19191d;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
  .main-container-mid {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mid-box {
    display: flex;
    margin: 20px;
    margin-top: 30px;
  }
  .mid-box-img > img {
    width: 100px;
  }
  .mid-box-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .mid-box-text > p {
    font-size: 24px;
    color: #a1a1a1;
  }
  .mid-box-text > h2 {
    font-size: 36px;
  }

  .main-container-low {
    color: white;
    display: flex;
    border: 1px solid #ad00ff;
    margin: 30px;
    padding: 20px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-weight: bold;
  }
  .main-container-low > h4 {
    font-size: 24px;
  }

  .main-container-low-left {
    width: 100px;
    margin-right: 30px;
    margin-left: 30px;
  }

  .CircularProgressbar-path {
    stroke: #00ff94 !important;
  }
  .CircularProgressbar-trail {
    stroke: #2a154f !important;
  }
  .CircularProgressbar-text {
    fill: #ad00ff !important;
  }
  .CircularProgressbar-background {
    fill: green;
  }
  .main-container-low-right {
    flex: 1;

    display: flex;

    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .main-container-low-right > div > p {
    font-size: 24px;
    color: #a1a1a1;
  }
  .main-container-low-right > div > h2 {
    font-size: 24px;
  }

  .main-container-right-up {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 30px;
    margin-left: 30px;
  }
  .listBox-container {
    margin: auto;
  }

  .main-container-right-up > p {
    font-size: 24px;
    color: #6b6b6b;
  }
  .main-container-right-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
  }
  .main-container-right-mid > p {
    font-size: 24px;
    color: #6b6b6b;
    align-self: flex-start;
    margin-left: 30px;
  }
  .mid-right-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 550px;
    height: 80px;
    background-color: #19191d;
    border-radius: 12px;
    margin: 10px;
    margin-top: 40px;
    gap: 30px;
    cursor: pointer;
  }
  .mid-right-box > h1 {
    font-size: 36px;
  }
  .mid-right-box > img {
    margin-left: 20px;
  }
  .main-container-right-low {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-container-right-low > p {
    font-size: 24px;
    color: #6b6b6b;
    align-self: flex-start;
    margin-left: 30px;
    margin-top: 40px;
  }

  .date-pick-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #19191d;
    gap: 30px;
    border: 0.7px solid white;
    margin-top: 20px;
    width: 550px;
  }

  .date-box {
    padding: 40px;

    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  .date-box > h1 {
    font-size: 24px;
  }
  .selected {
    background-color: #ad00ff;
  }
  .selectedBox {
    border: 1px solid white;
  }

  #divider1 {
    width: 450px;
    margin-bottom: 10px;
  }

  #divider2 {
    width: 450px;
    margin-top: 10px;
  }
  .main-container-right-footer {
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
  }
  .main-container-right-footer-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .right-footer-img {
    width: 30px;
    color: #ad00ff;
  }
  .right-footer-text {
    width: 80px;
    margin-left: 30px;
  }
  .snipe-img {
    width: 40px;
  }
  .date-time {
    font-size: 24px;
  }
}

@media screen and (max-width: 400px) {
  .App {
    height: 160vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url("./assets/img/Background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 50px;
    background-color: rgb(32, 32, 32);
  }
  .header-logo {
    height: 18px;
    padding-left: 25px;
  }
  .header-logOut {
    padding-right: 25px;
  }
  .header-logOut-btn {
    background-image: url("./assets/img/Ellipse\ 11.png");
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-position: center;
    cursor: pointer;
  }
  .containerSignIn {
    height: 513px;
    width: 367px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    background-color: rgb(32, 32, 32);
    border-radius: 4%;
  }

  .hero {
    margin-bottom: 10px;
  }

  .logIn-btn {
    height: 50px;
    width: 267px;
    border-radius: 99px;
    cursor: pointer;
    background-color: white;
    color: black;
  }
  .item {
    padding: 10px;
  }

  .hero-p {
    color: white;
  }

  .marketplaces {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .loggedInView {
    margin: auto;
    color: white;
  }

  .listBox {
    width: 350px;
    height: 200px;
    overflow: auto;
    text-align: center;
    margin-right: 20px;
    padding-left: -70px;
    margin-top: 10px;
    border-radius: 12px;
  }
  .listbox-button {
    width: 350px;
    border-radius: 12px;
  }
  .listbox-text {
    background-color: #19191d;
    padding: 15px 140px;
  }
  .listBox-option {
    color: red;
  }

  .main-container {
    width: 360px;
    height: 500px;

    display: flex;
    flex-direction: column;
    margin-top: -600px;
  }
  .main-container-left {
    flex: 1;
    background-color: black;
    border-radius: 12px;
  }
  .main-container-right {
    flex: 1;
    background-color: rgb(35, 35, 36);
    border-radius: 12px;
  }

  .main-container-left-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 120px;
  }
  .main-container-left-top > div > p {
    font-size: 12px;
    color: #a1a1a1;
  }

  .collection-img {
    width: 100px;
    border-radius: 12px;
  }
  .info-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 60px;
    margin-top: 30px;
  }

  .box {
    background-color: #19191d;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
  .main-container-mid {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mid-box {
    display: flex;
    margin: 20px;
    margin-top: 30px;
  }

  .mid-box-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .mid-box-text > p {
    font-size: 12px;
    color: #a1a1a1;
  }
  .mid-box-text > h2 {
    font-size: 22px;
  }

  .main-container-low {
    color: white;
    display: flex;
    border: 1px solid #ad00ff;
    margin: 30px;
    padding: 30px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-weight: bold;
  }

  .main-container-low-left {
    width: 100px;
  }
  .CircularProgressbar-path {
    stroke: #00ff94 !important;
  }
  .CircularProgressbar-trail {
    stroke: #2a154f !important;
  }
  .CircularProgressbar-text {
    fill: yellow;
  }
  .CircularProgressbar-background {
    fill: green;
  }
  .main-container-low-right {
    flex: 1;

    display: flex;

    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .main-container-low-right > div > p {
    font-size: 12px;
    color: #a1a1a1;
  }

  .main-container-right-up {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0px;
    margin-left: 5px;
  }
  .listBox-container {
    margin: auto;
  }
  ul {
    position: relative;
    z-index: 100;
  }
  ul::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .main-container-right-up > p {
    font-size: 12px;
    color: #6b6b6b;
    margin-top: 30px;
    margin-left: 25px;
  }
  .main-container-right-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
  }
  .main-container-right-mid > p {
    font-size: 12px;
    color: #6b6b6b;
    align-self: flex-start;
    margin-left: 30px;
  }
  .mid-right-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 350px;
    height: 45px;
    background-color: #19191d;
    border-radius: 12px;
    margin: 10px;
    margin-top: 20px;
    gap: 30px;
    cursor: pointer;
  }
  .mid-right-box > img {
    margin-left: 20px;
  }
  .main-container-right-low {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-container-right-low > p {
    font-size: 12px;
    color: #6b6b6b;
    align-self: flex-start;
    margin-left: 30px;
    margin-top: 20px;
  }

  .date-pick-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #19191d;
    gap: 30px;
    border: 0.7px solid white;
    margin-top: 20px;
    width: 350px;
  }

  .date-box {
    padding: 20px;

    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  .selected {
    background-color: #ad00ff;
  }
  .selectedBox {
    border: 1px solid white;
  }

  #divider1 {
    width: 350px;
    margin-bottom: 10px;
  }

  #divider2 {
    width: 350px;
    margin-top: 10px;
  }
  .main-container-right-footer {
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
  }
  .main-container-right-footer-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .right-footer-img {
    width: 30px;
    color: #ad00ff;
  }
  .print-btn {
    background-color: #fff;
    border-radius: 12px;
    color: #000;
    margin: 30px 10px 10px;
    padding: 20px 30px;
    width: 350px;
  }
}
