.CollectionPage {
  background: url("./assets/img/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.grid-div {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}

@media screen and (max-width: 800px) {
  .grid-div {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
  }
}
